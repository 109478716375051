import {
	Routes,
	Route,
	Link,
	Navigate,
	BrowserRouter as Router,
} from "react-router-dom";
import Login from "./pages/login/Login";
import "./App.css";
import Dashboard from "./pages/dashboard/Dashboard";
import { useCookies } from "react-cookie";

function App() {
	const [cookies, setCookie] = useCookies();

	const ExternalRoute = ({ component: Component, ...rest }) => {
		// Get URL param 'token'
		const token = new URLSearchParams(window.location.search).get("token");

		console.log("token", token);

		if (token) setCookie("token", token, { maxAge: 1800 });

		return (
			<Route
				{...rest}
				render={(props) =>
					cookies["token"] || token ? <Component /> : <Navigate to='/login' />
				}
			/>
		);
	};

	return (
		<Router>
			<Routes>
				<Route exact path='/external' component={Dashboard} />
				<Route
					exact
					path='/login'
					element={cookies["token"] ? <Navigate to='/' /> : <Login />}
				/>
				<Route
					exact
					path='/'
					element={cookies["token"] ? <Dashboard /> : <Navigate to='/login' />}
				/>
			</Routes>
		</Router>
	);
}

export default App;

import React, { useState } from "react";
import logo from "../../assets/psymep_black.png";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Navigate } from "react-router";

function formatDateYMDHM(date) {
	if (date) {
		var d = new Date(date);
		d = new Date(d.toLocaleString("en-US", { timeZone: "Europe/Bucharest" }));
		var month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear(),
			hours = "" + d.getHours(),
			minutes = "" + d.getMinutes();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		if (hours.length < 2) hours = "0" + hours;
		if (minutes.length < 2) minutes = "0" + minutes;

		return [year, month, day].join("/") + " - " + hours + ":" + minutes;
	} else {
		return "";
	}
}

Date.prototype.addHours = function (h) {
	this.setHours(this.getHours() + h);
	return this;
};

export default function Dashboard() {
	const [cookies, setCookie, removeCookie] = useCookies();
	const [state, setState] = useState();
	const [error, setError] = useState();
	const [clients, setClients] = useState([]);
	const [an, setAn] = useState(2022);
	const [luna, setLuna] = useState("07");
	const [emailStatus, setEmailStatus] = useState();
	const [invoiceStatus, setInvoiceStatus] = useState();
	const [sendInvoice, setSendInvoice] = useState();
	const [downloadCsv, setDownloadCsv] = useState();
	const [progress, setProgress] = useState();
	const [data, setData] = useState([]);
	const [dataCanceled, setDataCanceled] = useState([]);
	const [raports, setRaports] = useState();
	const [raport, setRaport] = useState();
	const [invoice, setInvoice] = useState();
	const [sendInvoices, setSendInvoices] = useState();
	const [sendRaports, setSendRaports] = useState();

	console.log("Canceled", dataCanceled, data);

	const logoutHandler = () => {
		removeCookie("token");
		setState("loggedout");
	};

	const getDataHandler = () => {
		setState("pending");
		console.log(an, luna);
		axios
			.post("https://psymep-node-api.onrender.com/get-data-wp", {
				token: cookies["token"],
				year: an,
				month: luna,
			})
			.then((res) => {
				console.log("get-data", res.data);
				setData(res.data.clientsInvoice);
				setDataCanceled(res.data.canceledBookings);
				setState("");
			})
			.catch((err) => {
				console.log("err get_data_wp", err);
				setError(err.message);
				setState("");
			});
	};

	const sendEmailHandler = () => {
		setEmailStatus("pending");

		axios
			.post("https://psymep-node-api.onrender.com/send-mail", { luna, an })
			.then((res) => {
				setEmailStatus("success");
			})
			.catch((err) => {
				console.log("1102err", err);
				// setEmailStatus(error.message);
			});
	};

	const sendEmailUserHandler = (client) => {
		client.emailStatus = "pending";
		setData([...data]);

		axios
			.post("https://psymep-node-api.onrender.com/send-mail-user", {
				luna,
				an,
				id: client.id,
			})
			.then((res) => {
				client.emailStatus = "success";
				setData([...data]);
			})
			.catch((err) => {
				console.log("1102err", err);
				// setEmailStatus(error.message);
			});
	};

	const sendInvoiceHandler = () => {
		setSendInvoice("pending");
		axios
			.post("https://psymep-node-api.onrender.com/smartbill-email-invoices", {})
			.then((res) => {
				setSendInvoice("success");
			})
			.catch((err) => {
				setSendInvoice(error.message);
			});
	};
	const downloadCsvHandler = () => {
		let bookings = [];
		data.forEach((element) => {
			element.bookings.forEach((booking) => {
				booking.name = element.name;
			});
			bookings = [...bookings, ...element.bookings];
		});
		bookings = [...bookings, ...dataCanceled];
		bookings = bookings.map((booking) => {
			const data = {
				Nume: booking.name,
				Locatie: booking.provider,
				Data: booking.start_date,
				"Data anulare": booking.canceled_date ? booking.canceled_date : "",
				"Data creare": booking.create_date,
				Cost: booking.cost,
			};

			return data;
		});
		const csvString = [
			["Nume", "Locatie", "Data", "Data anulare", "Data creare", "Cost"],
			...bookings.map((item) => [
				item.Nume,
				item.Locatie,
				formatDateYMDHM(item.Data),
				item["Data anulare"]
					? formatDateYMDHM(new Date(item["Data anulare"]))
					: "",
				formatDateYMDHM(new Date(item["Data creare"])),
				item.Cost,
			]),
		]
			.map((e) => e.join(","))
			.join("\n");
		// var encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvString);
		// window.open(encodedUri);
		var hiddenElement = document.createElement("a");
		hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvString);
		hiddenElement.target = "_blank";

		//provide the name for the CSV file to be downloaded
		hiddenElement.download = "rapoarte.csv";
		hiddenElement.click();
	};
	const createInvoicesHandler = () => {
		setInvoiceStatus("pending");
		console.log("luna", luna);
		axios
			.post("https://psymep-node-api.onrender.com/smartbill-invoice", {
				an,
				luna,
			})
			.then((res) => {
				setInvoiceStatus("success");
			})
			.catch((err) => {
				setInvoiceStatus(err.message);
			});
	};

	const creeazaRapoarteHandler = () => {
		setRaports("pending");
		axios
			.post("https://psymep-node-api.onrender.com/create-reports", {
				luna,
				an,
				token: cookies["token"],
			})
			.then((res) => {
				setRaports(true);
			})
			.catch((err) => {
				setError(err.message);
			});
	};

	const creeazaFacturiHandler = () => {
		setInvoice("pending");
		axios
			.post("https://psymep-node-api.onrender.com/smartbill-invoice-wp", {
				luna,
				an,
				token: cookies["token"],
			})
			.then((res) => {
				setInvoice(true);
			})
			.catch((err) => {
				setError(err.message);
			});
	};

	const trimiteFacturiHandler = () => {
		setSendInvoices("pending");
		axios
			.post(
				"https://psymep-node-api.onrender.com/smartbill-email-invoices-wp",
				{
					luna,
					an,
					token: cookies["token"],
				}
			)
			.then((res) => {
				setSendInvoices(true);
			})
			.catch((err) => {
				setError(err.message);
			});
	};

	const trimiteRapoarteHandler = () => {
		setSendRaports("pending");
		axios
			.post("https://psymep-node-api.onrender.com/send-mail-wp", {
				luna,
				an,
				token: cookies["token"],
			})
			.then((res) => {
				setSendRaports(true);
			})
			.catch((err) => {
				setError(err.message);
			});
	};

	const reseteazaRapoarteHandler = () => {
		setSendRaports("pending");
		axios
			.post("https://psymep-node-api.onrender.com/reseteaza-rapoarte", {
				luna,
				an,
				token: cookies["token"],
			})
			.then((res) => {})
			.catch((err) => {});
	};

	const smartbillListHandler = () => {
		axios
			.post("https://psymep-node-api.onrender.com/smartbill-list", {
				luna,
				an,
				token: cookies["token"],
			})
			.then((res) => {})
			.catch((err) => {});
	};

	return (
		<>
			<nav class='navbar navbar-light bg-light'>
				{state === "loggedout" && <Navigate to='/login' />}
				<div class='container'>
					<a class='navbar-brand' href='#'>
						<img src={logo} alt='' style={{ width: "120px" }} />
					</a>
					<button className='btn btn-outline-dark' onClick={logoutHandler}>
						Log out
					</button>
				</div>
			</nav>
			<div className='container py-4'>
				<div className='row justify-content-center text-center'>
					<div className='col-lg-7'>
						<select
							className='form-control mb-2'
							defaultValue={an}
							onChange={(e) => setAn(e.target.value)}>
							<option value='2022'>2022</option>
							<option value='2023'>2023</option>
							<option value='2024'>2024</option>
							<option value='2025'>2025</option>
							<option value='2026'>2026</option>
							<option value='2027'>2027</option>
							<option value='2028'>2028</option>
						</select>
						<select
							className='form-control mb-2'
							defaultValue={luna}
							onChange={(e) => setLuna(e.target.value)}>
							<option value='01'>Jan</option>
							<option value='02'>Feb</option>
							<option value='03'>Mar</option>
							<option value='04'>Apr</option>
							<option value='05'>May</option>
							<option value='06'>Jun</option>
							<option value='07'>Jul</option>
							<option value='08'>Aug</option>
							<option value='09'>Sep</option>
							<option value='10'>Oct</option>
							<option value='11'>Nov</option>
							<option value='12'>Dec</option>
						</select>

						<div className='row'>
							{an && luna && (
								<div className='col-lg-6'>
									<button
										onClick={getDataHandler}
										className='btn btn-dark btn-block w-100 mb-3'
										disabled={state === "pending"}>
										Get data
										{state === "pending" && (
											<span style={{ marginRight: "-24px" }} className='ps-2'>
												<div
													className='spinner-border spinner-border-sm text-light'
													role='status'>
													<span className='visually-hidden'>Loading...</span>
												</div>
											</span>
										)}
									</button>
								</div>
							)}
							{data.length !== 0 && (
								<div className='col-6 mb-3'>
									<button
										onClick={creeazaRapoarteHandler}
										className={
											"btn w-100 " + (raports ? "btn-success" : "btn-dark")
										}>
										Creeaza rapoarte
									</button>
								</div>
							)}
							{luna && an && (
								<div className='col-6 mb-3'>
									<button
										onClick={creeazaFacturiHandler}
										className={
											"btn w-100 " + (invoice ? "btn-success" : "btn-dark")
										}>
										Creeaza facturi
									</button>
								</div>
							)}
							{luna && an && (
								<div className='col-6 mb-3'>
									<button
										onClick={trimiteFacturiHandler}
										className={
											"btn w-100 " + (sendInvoices ? "btn-success" : "btn-dark")
										}>
										Trimite facturi
									</button>
								</div>
							)}
							{data.lenght !== 0 && (
								<div className='col-lg-6 mb-3'>
									<button
										className='btn btn-dark w-100'
										onClick={downloadCsvHandler}>
										Download CSV
									</button>
								</div>
							)}
							{luna && an && (
								<div className='col-6 mb-3'>
									<button
										onClick={trimiteRapoarteHandler}
										className={
											"btn w-100 " + (sendRaports ? "btn-success" : "btn-dark")
										}>
										Trimite rapoarte
									</button>
								</div>
							)}
							{luna && an && (
								<div className='col-6 mb-3'>
									<button
										onClick={reseteazaRapoarteHandler}
										className={"btn w-100 btn-dark"}>
										Reseteaza
									</button>
								</div>
							)}
							{luna && an && (
								<div className='col-6 mb-3'>
									<button
										onClick={smartbillListHandler}
										className={"btn w-100 btn-dark"}>
										Smartbill list
									</button>
								</div>
							)}
						</div>
						{/* {data.length !== 0 && (
							<div className='row mt-3'>
								<div className='col-lg-6 mb-3'>
									{emailStatus !== "success" ? (
										<button
											disabled={emailStatus === "pending"}
											className='btn btn-dark w-100'
											onClick={sendEmailHandler}>
											Send email
											{emailStatus === "pending" && (
												<span style={{ marginRight: "-24px" }} className='ps-2'>
													<div
														className='spinner-border spinner-border-sm text-light'
														role='status'>
														<span className='visually-hidden'>Loading...</span>
													</div>
												</span>
											)}
										</button>
									) : (
										<button className='btn btn-success w-100' disabled>
											Emails sent!
										</button>
									)}
								</div>
								<div className='col-lg-6 mb-3'>
									{invoiceStatus !== "success" ? (
										<button
											className='btn btn-dark w-100'
											onClick={createInvoicesHandler}>
											Create invoices
											{invoiceStatus === "pending" && (
												<span style={{ marginRight: "-24px" }} className='ps-2'>
													<div
														className='spinner-border spinner-border-sm text-light'
														role='status'>
														<span className='visually-hidden'>Loading...</span>
													</div>
												</span>
											)}
										</button>
									) : (
										<button className='btn btn-success w-100' disabled>
											{" "}
											Invoices created!
										</button>
									)}
								</div>
								<div className='col-lg-6 mb-3'>
									{sendInvoice !== "success" ? (
										<button
											className='btn btn-dark w-100'
											onClick={sendInvoiceHandler}>
											Trimite facturi
											{sendInvoice === "pending" && (
												<span style={{ marginRight: "-24px" }} className='ps-2'>
													<div
														className='spinner-border spinner-border-sm text-light'
														role='status'>
														<span className='visually-hidden'>Loading...</span>
													</div>
												</span>
											)}
										</button>
									) : (
										<button className='btn btn-success w-100' disabled>
											{" "}
											Facturile au fost trimise!
										</button>
									)}
								</div>
								<div className='col-lg-6 mb-3'>
									<button
										className='btn btn-dark w-100'
										onClick={downloadCsvHandler}>
										Download CSV
									</button>
								</div>
							</div>
						)} */}
					</div>
				</div>
				<div>
					{error && (
						<div
							className='alert alert-danger d-flex align-items-center py-2'
							role='alert'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill='currentColor'
								className='bi bi-exclamation-triangle'
								viewBox='0 0 16 16'>
								<path d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z' />
								<path d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z' />
							</svg>
							<div className='ps-2'>{error}</div>
						</div>
					)}
					{data.length !== 0 && (
						<table class='table mt-4'>
							<thead>
								<tr>
									<th scope='col'>#</th>
									<th scope='col'>Name</th>
									<th scope='col'>Tip 1</th>
									<th scope='col'>Tip 2</th>
									<th scope='col'>Email</th>
									<th scope='col'>Firma</th>
								</tr>
							</thead>
							<tbody>
								{data.map((client, index) => (
									<tr>
										<th scope='row'>{index + 1}</th>
										<td>{client.name}</td>
										<td>{client.tip1}</td>
										<td>{client.tip2}</td>
										<td>{client.email}</td>
										<td>{client.firm}</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</>
	);
}

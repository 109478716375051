import React, { useRef, useState } from "react";
import "./Login.css";
import logo from "../../assets/psymep_black.png";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Login() {
	const [error, setError] = useState();
	const [state, setState] = useState();
	const userRef = useRef();
	const passwordRef = useRef();
	const [cookies, setCookie] = useCookies();
	const loginHandler = () => {
		setState("pending");
		axios
			.post("https://psymep-node-api.onrender.com/login-wp", {
				username: userRef.current.value,
				password: passwordRef.current.value,
			})
			.then((res) => {
				console.log(
					"res",
					res.data,
					"https://psymep-node-api.onrender.com/login-wp"
				);
				if (res.data === "Request failed with status code 403") {
					setError(res.data);
					setState("");
					setState("");
				} else {
					setCookie("token", res.data, { maxAge: 1800 });
					setState("success");
				}
			})
			.catch((err) => {
				setError(err.message);
				setState("");
			});
	};

	return (
		<section
			className='h-100 gradient-form'
			style={{ backgroundColor: "#eee" }}>
			{state === "success" && <Navigate to='/' />}
			<div className='container py-5 h-100'>
				<div className='row d-flex justify-content-center align-items-center h-100'>
					<div className='col-xl-10 mb-5'>
						<div className='row g-0 justify-content-center'>
							<div className='col-lg-8'>
								<div className='card text-black border-0 shadow-sm'>
									<div className='card-body p-md-5 mx-md-4'>
										<div className='text-center'>
											<img
												className='mb-4'
												src={logo}
												style={{ width: "185px" }}
												alt='logo'
											/>
										</div>
										<p className='text-center mb-4'>
											<a
												className='text-warning'
												href='https://www.psymep.ro/'
												target='_blank'>
												Psymep
											</a>
											<span> login credentials</span>
										</p>

										<form>
											<div className='mb-4'>
												<input
													ref={userRef}
													type='text'
													className='form-control'
													id='psymep-user'
													placeholder='User'
												/>
											</div>

											<div className='mb-4'>
												<input
													ref={passwordRef}
													type='password'
													id='psymep-password'
													className='form-control'
													placeholder='Password'
												/>
											</div>

											<div className='text-center pt-1'>
												<button
													onClick={loginHandler}
													className='btn btn-primary btn-block w-100 border-none fa-lg gradient-custom-2 mb-3'
													type='button'>
													Log in
													{state === "pending" && (
														<span
															style={{ marginRight: "-24px" }}
															className='ps-2'>
															<div
																className='spinner-border spinner-border-sm text-light'
																role='status'>
																<span className='visually-hidden'>
																	Loading...
																</span>
															</div>
														</span>
													)}
												</button>
											</div>
										</form>
										{error && (
											<div
												className='alert alert-danger d-flex align-items-center py-2'
												role='alert'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													fill='currentColor'
													className='bi bi-exclamation-triangle'
													viewBox='0 0 16 16'>
													<path d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z' />
													<path d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z' />
												</svg>
												<div className='ps-2'>{error}</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
